import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-active-match-card',
  templateUrl: './active-match-card.component.html',
  styleUrls: ['./active-match-card.component.scss'],
})
export class ActiveMatchCardComponent implements OnInit {
  @Input() match: any;

  @Output() liveClicked = new EventEmitter<void>();

  @Output() teamClicked = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}

  goToLivePage(): void {
    this.liveClicked.emit();
  }

  goToTeam(): void {
    this.teamClicked.emit();
  }
}
