<div class="card shadow-sm text-dark bg-light m-2">
    <div class="card-header">{{ match.versus_description }}</div>
    <div class="card-body backdrop">
        <div class="d-flex justify-content-between align-items-start">
            <div class="text-wrap text-start" style="width: 8rem; font-size: small">
                {{ match.teamA.title_description }}
            </div>
            <div class="text-wrap text-end" style="width: 8rem; font-size: small">
                {{ match.teamB.title_description }}
            </div>
        </div>
        <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex align-items-center">
                <img class="logo-back mx-1" src="{{ match.teamA.team_logo }}" height="50px" width="50px" />
                <div>{{ match.teamA.title }}</div>
            </div>
            <div>{{ match.date }} {{ match.time }}</div>
            <div class="d-flex align-items-center">
                <div>{{ match.teamB.title }}</div>
                <img class="logo-back mx-1" src="{{ match.teamB.team_logo }}" height="50px" width="50px" />
            </div>
        </div>
    </div>
    <div class="card-footer text-end">
        <button type="button" class="btn btn-primary btn-sm"  *ngIf="matchExited" (click)="createTeam()">
      Create
    </button>
    <button type="button" class="btn btn-primary btn-sm"  *ngIf="!matchExited" (click)="teamPreview()">
        TeamPreview
      </button>
    </div>
</div>