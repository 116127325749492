<mat-drawer-container autosize style="height: 100vh">
  <mat-drawer #drawer class="sidenav-items" mode="over" position="end">
    <app-signout></app-signout>
  </mat-drawer>

  <div class="sidenav-content">
    <div class="icon">
      <button
        mat-icon-button
        class="menu_icon"
        mat-button
        (click)="drawer.toggle()"
      >
        <mat-icon>menu</mat-icon>
      </button>
    </div>
    <!-- create team data -->
    <div class="headercls">
      <div class="col-xs-12">
        <div class="teamInfo">
          <p style="color: #fff">Selected Players : {{ maxPalyers }}/11</p>
          <p style="color: #fff; text-align: center">
            Team {{ teamAName }} {{ maxTeam1Count }} VS TEAM {{ teamBName }}
            {{ maxTeam2Count }}
          </p>
        </div>
      </div>
    </div>
    <mat-tab-group (selectedTabChange)="tabChagned($event)">
      <mat-tab label="WK({{ maxWKCount }})"></mat-tab>
      <mat-tab label="BAT({{ maxBATCount }})"></mat-tab>
      <mat-tab label="AL({{ maxALCount }})"></mat-tab>
      <mat-tab label="BOL({{ maxBOLCount }})"></mat-tab>
    </mat-tab-group>

    <div class="player_display_area">
      <div *ngFor="let player of totalTeams; let i = index">
        <div *ngIf="selectedTab == 0">
          <div *ngIf="player.category == 'W'">
            <div class="col-md-12 mb-12">
              <div class="player_cls">
                <div>
                  <img src="../../assets/images/1.png" alt="" />
                  <span class="player_name">{{ player.name }}</span>
                </div>
                <input
                  class="form-check-input checkbox-big"
                  type="checkbox"
                  id="{{ player.pid }}"
                  (change)="changeStatus($event)"
                  disabled="{{ player.disabled }}"
                  [checked]="player.checked"
                />
                <!-- <span class="checkmark"></span> -->
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="selectedTab == 1">
          <div *ngIf="player.category == 'B'">
            <div class="col-md-12 mb-12">
              <div class="player_cls">
                <div>
                  <img src="../../assets/images/1.png" alt="" />
                  <span class="player_name">{{ player.name }}</span>
                </div>
                <input
                  class="form-check-input checkbox-big"
                  type="checkbox"
                  id="{{ player.pid }}"
                  (change)="changeStatus($event)"
                  disabled="{{ player.disabled }}"
                  [checked]="player.checked"
                />
                <!-- <span class="checkmark"></span> -->
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="selectedTab == 2">
          <div *ngIf="player.category == 'AL'">
            <div class="col-md-12 mb-12">
              <div class="player_cls">
                <div>
                  <img src="../../assets/images/1.png" alt="" />
                  <span class="player_name">{{ player.name }}</span>
                </div>
                <input
                  class="form-check-input checkbox-big"
                  type="checkbox"
                  id="{{ player.pid }}"
                  (change)="changeStatus($event)"
                  disabled="{{ player.disabled }}"
                  [checked]="player.checked"
                />
                <!-- <span class="checkmark"></span> -->
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="selectedTab == 3">
          <div *ngIf="player.category == 'BL'">
            <div class="col-md-12 mb-12">
              <div class="player_cls">
                <div>
                  <img src="../../assets/images/1.png" alt="" />
                  <span class="player_name">{{ player.name }}</span>
                </div>
                <input
                  class="form-check-input checkbox-big"
                  type="checkbox"
                  id="{{ player.pid }}"
                  (change)="changeStatus($event)"
                  disabled="{{ player.disabled }}"
                  [checked]="player.checked"
                />
                <!-- <span class="checkmark"></span> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style="width: 100%; height: 200px; display: block; float: left"
      ></div>
    </div>

    <div class="choosebutton" *ngIf="nextButton">
      <span mat-button (click)="selectCaptain()">Choose Captain</span>
    </div>
    <!-- create team data -->
  </div>
</mat-drawer-container>
