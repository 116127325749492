import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-future-match-card',
  templateUrl: './future-match-card.component.html',
  styleUrls: ['./future-match-card.component.scss'],
})
export class FutureMatchCardComponent implements OnInit {
  @Input() match: any;

  @Input() matchExited: any;

  @Output() createTeamEvent = new EventEmitter<void>();

  @Output() teamPreviewClicked = new EventEmitter<void>();

  constructor() {}

  createTeam(): void {
    this.createTeamEvent.emit();
  }
  teamPreview(): void {
    this.teamPreviewClicked.emit();
  }
  ngOnInit(): void {}
}
