<!-- Lottie
<script src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script> -->
<mat-drawer-container autosize style="height: 100vh">
    <mat-drawer #drawer class="sidenav-items" mode="over" position="end">
        <app-signout></app-signout>
    </mat-drawer>

    <div class="sidenav-content">
        <mat-toolbar color="primary" class="example-toolbar">
            <span><img
          height="32"
          class="me-2 logoIPL"
          src="../../assets/images/tslogo.png"
      /></span>
            <span class="example-spacer"></span>
            <button mat-icon-button class="menu_icon" mat-button (click)="drawer.toggle()">
        <mat-icon>menu</mat-icon>
      </button>
        </mat-toolbar>
        <!-- fantacy team data -->
        <div class="page-bg d-flex flex-column mx-2">
            <h3 class="mb-n1 mx-2"><b>Winners</b></h3>
            <app-match-winner-card *ngFor="let winner of recentWinner; let i = index" [winner]="winner"></app-match-winner-card>

            <h3 class="mb-n1 mx-2"><b>Upcoming Matches</b></h3>
            <app-future-match-card *ngFor="let match of matchByDate; let i = index" [match]="match" [matchExited]="matchExited[i]" 
            (createTeamEvent)="gotoCreateTeam(match.teamA.title, match.teamB.title, match.mid)" 
            (teamPreviewClicked)="gotoDashboard(match.mid, userName)"
        ></app-future-match-card>

            <h3 class="mb-n1 mx-2"><b>Live Matches</b></h3>
            <app-active-match-card *ngFor="let liveMatch of liveMatches; let i = index" [match]="liveMatch" (liveClicked)="goToLivePage(liveMatch.match_key)" (teamClicked)="gotoDashboard(liveMatch.mid, userName)"></app-active-match-card>
        </div>
        <!-- fantacy team data -->
    </div>
</mat-drawer-container>