<div class="card text-dark bg-light m-2">
    <div class="card-header d-flex justify-content-between">
        <div>{{ winner.match.versus_description }}</div>
        <div>{{ winner.match.date }}</div>
    </div>
    <div class="card-body d-flex align-items-center justify-content-center">
        <span id="lottie-span" class="d-flex justify-content-center lottiePosition">
      <lottie-player
        id="firstLottie"
        src="https://assets10.lottiefiles.com/packages/lf20_5hufvwkz.json"
        background="transparent"
        speed="1"
        style="width: 70px; height: 70px"
        loop
        autoplay
      ></lottie-player>
    </span>
        <div class="text-center">
            <!-- <h4 class="card-title">Murthy</h4> -->
            <p class="card-text fs-6" >{{winner.winners[0].firstName}} {{winner.winners[0].lastName}}</p>
        </div>
        <span id="lottie-span" class="d-flex justify-content-center lottiePosition">
      <lottie-player
        id="firstLottie"
        src="https://assets10.lottiefiles.com/packages/lf20_5hufvwkz.json"
        background="transparent"
        speed="1"
        style="width: 70px; height: 70px"
        loop
        autoplay
      ></lottie-player>
    </span>
    </div>
    <!-- <div class="card-footer text-center">
<small>Lorem ipsum dolor sit amet consectetur.</small>
</div> -->
</div>