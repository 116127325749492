<h2 mat-dialog-title class="modal_title">
    {{data.teamTitel}}
</h2>

<mat-dialog-content>
    <div *ngIf = "individualTeamScore.length == 0">
        {{noResult}}
    </div>
    <div *ngIf = "individualTeamScore.length != 0">
        <table style="width: 100%; border-collapse: collapse; " class="table">
            <tr>
                <th>S.NO</th>
                <th>USER</th>
                <th>SCORE</th>
            </tr>
            <tr *ngFor = "let match of individualTeamScore; let i=index;">
                <td>{{i+1}}</td>
                <td>{{match.user.email}}</td>
                <td>{{match.score}}</td>
            </tr>
        </table>
    </div>
    <!-- loading spinner -->
    <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">  
        <p style="color: white">Please Wait... </p>  
    </ngx-spinner> 
    <!-- loading spinner -->
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close class="btn btn-danger">Close</button>
</mat-dialog-actions>
