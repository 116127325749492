import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-match-winner-card',
  templateUrl: './match-winner-card.component.html',
  styleUrls: ['./match-winner-card.component.scss']
})
export class MatchWinnerCardComponent implements OnInit {
  @Input() winner: any=[];
  constructor() { }

  ngOnInit(): void {
  }
  
}
